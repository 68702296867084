/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface InvitedUserInfoDTO {
  email?: string;
  invitationStatus?:
    | 'ACCEPTED'
    | 'PENDING_TO_REGISTERED_SET_REGISTER_FORM'
    | 'PENDING_TO_REGISTERED_SET_REGISTER_FORM_NO_PASSWORD'
    | 'PENDING_TO_REGISTERED_SET_PASSWORD_ONLY'
    | 'ALREADY_REGISTERED'
    | 'ALREADY_REGISTERED_SET_PASSWORD_ONLY';
}
