/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EligibilityDetailsAndAuthorisation {
  isConnectedToExistingFacility: boolean;
  adjacentFacilityId?: string;
  agreementType: 'ENVIRONMENTAL_PERMITTING_REGULATIONS' | 'ENERGY_INTENSIVE';
  erpAuthorisationExists?: boolean;
  authorisationNumber?: string;
  regulatorName?:
    | 'ENVIRONMENT_AGENCY'
    | 'SCOTTISH_ENVIRONMENT_PROTECTION_AGENCY'
    | 'DEPARTMENT_OF_AGRICULTURE_ENVIRONMENT_AND_RURAL_AFFAIRS'
    | 'NATURAL_RESOURCES_WALES'
    | 'OTHER';
  permitFile?: string;
}
