/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The user feedback
 */
export interface UserFeedbackDto {
  satisfactionRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  satisfactionRateReason?: string;
  userRegistrationRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  userRegistrationRateReason?: string;
  onlineGuidanceRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  onlineGuidanceRateReason?: string;
  creatingAccountRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  creatingAccountRateReason?: string;
  onBoardingRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  onBoardingRateReason?: string;
  tasksRate:
    | 'VERY_SATISFIED'
    | 'SATISFIED'
    | 'NEITHER_SATISFIED_NOR_DISSATISFIED'
    | 'DISSATISFIED'
    | 'VERY_DISSATISFIED'
    | 'NOT_APPLICABLE_NOT_USED_YET';
  tasksRateReason?: string;
  improvementSuggestion?: string;
}
