/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The request note request
 */
export interface RequestNoteRequest {
  note: string;
  files?: Array<string>;
  requestId: string;
}
