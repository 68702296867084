/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface TargetComposition {
  calculatorFile: string;
  measurementType: 'ENERGY_KWH' | 'ENERGY_MWH' | 'ENERGY_GJ' | 'CARBON_KG' | 'CARBON_TONNE';
  agreementCompositionType: 'ABSOLUTE' | 'RELATIVE' | 'NOVEM';
  isTargetUnitThroughputMeasured?: boolean;
  throughputUnit?: string;
  conversionFactor?: number;
  conversionEvidences?: Array<string>;
}
