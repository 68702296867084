/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UnderlyingAgreementVariationDetails {
  reason: string;
  modifications: Array<
    | 'AMEND_OPERATOR_OR_ORGANISATION_NAME'
    | 'AMEND_OPERATOR_OR_ORGANISATION_TARGET_UNIT_ADDRESS'
    | 'AMEND_RESPONSIBLE_PERSON_PERSONAL_DETAILS'
    | 'AMEND_ONE_OR_MORE_FACILITIES_NAME'
    | 'ADD_ONE_OR_MORE_FACILITIES_TO_AGREEMENT'
    | 'REMOVE_ONE_OR_MORE_FACILITIES_FROM_AGREEMENT'
    | 'AMEND_70_PERCENT_RULE_EVALUATION'
    | 'STRUCTURAL_CHANGE'
    | 'REVIEW_OF_70_PERCENT_RULE'
    | 'ERROR_DISCOVERY'
    | 'ADDITION_OR_REMOVAL_ONE_OR_MORE_FACILITIES_FROM_AGREEMENT'
    | 'REPLACING_ESTIMATED_WITH_ACTUAL_VALUES'
    | 'UNEXPECTED_POWER_SUPPLY_DISRUPTION_DURING_TARGET_PERIOD'
    | 'THROUGHPUT_DROPPING_MORE_THAN_10_PERCENT_DURING_TARGET_PERIOD'
    | 'CHANGE_BETWEEN_RELATIVE_AND_NOVEM_TARGET_TYPES'
    | 'CHANGE_THROUGHPUT_UNIT'
    | 'ANY_CHANGES_NOT_COVERED'
  >;
}
