/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface HttpHeadersHostAddress {
  hostAddress?: string;
  address?: string;
  hostName?: string;
  linkLocalAddress?: boolean;
  multicastAddress?: boolean;
  anyLocalAddress?: boolean;
  loopbackAddress?: boolean;
  siteLocalAddress?: boolean;
  mcglobal?: boolean;
  mcnodeLocal?: boolean;
  mclinkLocal?: boolean;
  mcsiteLocal?: boolean;
  mcorgLocal?: boolean;
  canonicalHostName?: string;
}
